import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import SubMenu from "../components/navigation/SubMenu"
import Breadcrumbs from "../components/navigation/Breadcrumbs"
import Title from "../components/Title"
import PageCards from "../components/PageCards"
import PageComponents from "../components/strapiDynamicZone/PageComponents"

const StandardPage = ({ data, pageContext }) => {
  const {
    metaPageDesc,
    metaPageTitle,
    visibility,
    headerImage,
    slug,
    title,
    subtitle,
    body,
  } = data.thisPage

  const testimonials = data.testimonials.nodes

  const childPages = data.thisPage.child_pages
  childPages.sort((a, b) => a.teaserPriority - b.teaserPriority)
  const subMenu = data.subNav
  const classNameRightCol =
    data.subNav == null ? "right-column-hidden" : "right-column"
  const currentPage = {
    slug,
    title,
  }
  const breadcrumbs = pageContext.breadcrumbs
  const image = headerImage && getImage(headerImage.localFile)
  const altText =
    headerImage && headerImage.alternativeText
      ? headerImage.alternativeText
      : `header image`

  return (
    <Wrapper>
      <Layout>
        <Seo
          title={`${metaPageTitle} | AWIS`}
          description={metaPageDesc}
          noIndex={visibility !== "public"}
        />
        {headerImage && (
          <GatsbyImage image={image} className="header-img" alt={altText} />
        )}

        <div className="page-margins">
          <Breadcrumbs breadcrumbs={breadcrumbs} currentPage={currentPage} />

          <Title title={title} subtitle={subtitle} />

          <div className="columns">
            <div className="left-column">
              <PageComponents
                styleClass={"page-content"}
                body={body}
                testimonials={testimonials}
              />
            </div>
            <aside className={classNameRightCol}>
              <SubMenu subMenu={subMenu} />
            </aside>
          </div>
        </div>

        {childPages.length > 0 && <PageCards pages={childPages} />}
      </Layout>
    </Wrapper>
  )
}

export default StandardPage

const Wrapper = styled.section`
  .header-img {
    height: 24rem;
    width: 100%;
    z-index: 0;
  }
  .page-content {
    width: 100%;
    /* max-width: 1170px; */
  }
  .columns {
    display: flex;
    justify-content: space-between;
  }
  .left-column {
    border-top: 1px solid var(--clr-grey-5);
    padding-top: 3rem;
    margin-bottom: 5rem;
    width: 100%;
  }
  .right-column-hidden,
  .right-column {
    display: none;
    margin-left: 0;
    width: 0;
  }

  /* Media Queries */
  @media screen and (min-width: 760px) {
    .right-column {
      display: block;
      min-width: 25rem;
      margin-left: 5rem;
    }
    .top-sub-menu {
      display: none;
    }
  }
  @media print {
    .right-column {
      display: none;
      margin-left: 0;
      width: 0;
    }
  }
`

export const query = graphql`
  query GetSingleDetailPage($slug: String, $subMenuRoot: String) {
    thisPage: strapiPages(slug: { eq: $slug }) {
      metaPageTitle
      metaPageDesc
      visibility
      slug
      title
      subtitle
      body
      headerImage {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      child_pages {
        metaPageDesc
        id
        title
        subtitle
        slug
        visibility
        teaserPriority
        teaserImage {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    subNav: strapiMainMenu(url: { eq: $subMenuRoot }) {
      url
      label
      subLinks {
        id
        url
        label
      }
    }
    testimonials: allStrapiTestimonials(
      filter: { page: { slug: { eq: $slug } } }
      sort: { fields: updatedAt, order: DESC }
    ) {
      nodes {
        id
        personName
        stars
        comment
        posted(formatString: "ll")
        createdAt(formatString: "ll")
        page {
          slug
        }
      }
    }
  }
`
