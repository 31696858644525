import React from "react"
import styled from "styled-components"
import NavLink from "./NavLink"

const SubMenu = props => {
  const subMenuHeader = props.subMenu ? props.subMenu.label : null
  const linkData = props.subMenu ? props.subMenu.subLinks : []

  const subLinks = linkData.map(subLink => {
    return (
      <li key={subLink.id}>
        <NavLink to={subLink.url} className="link">
          {subLink.label}
        </NavLink>
      </li>
    )
  })

  return subLinks.length > 0 ? (
    <Wrapper className="sub-menu">
      <NavLink to={props.subMenu.url} className="sub-menu-header">
        <header className="title">{subMenuHeader}</header>
      </NavLink>
      <div className="content">
        <nav>
          <ul className="link">{subLinks}</ul>
        </nav>
      </div>
    </Wrapper>
  ) : null
}

export default SubMenu

const Wrapper = styled.section`
  .sub-menu {
    width: 100%;
  }
  .sub-menu-header {
    width: 100%;
    border: none;
    padding: 0;
  }
  .sub-menu-header.collapsable {
    cursor: pointer;
  }
  .title {
    margin: 0;
    background-color: var(--clr-primary-1);
    color: white;
    text-transform: uppercase;
    padding: 0.5rem 2rem;
    font-size: 2rem;
    text-align: left;
  }
  .content {
    background-color: var(--clr-grey-7);
    padding: 1rem 1.5rem;
    line-height: 3;
    transition: all 0.3s ease;
    height: auto;
    position: relative;
    display: block;
  }

  .link > li {
    border-bottom: 1px dashed var(--clr-grey-5);
  }
  a {
    display: block;
    font-size: 1.6rem;
    padding: 0 0.5rem;
    font-weight: normal;
  }
`
